import React from 'react'
import Layout from '../../components/Layout'
import GradientContainer from '../../components/GradientContainer'
import Heading from '../../components/Heading'
import Highlight from '../../components/Highlight'
import {Helmet} from 'react-helmet'
import awardis from './../../images/awardis.jpg'

export default function Impressum() {
  return (
    <Layout>
      <Helmet>
        <title>Impressum</title>
      </Helmet>
      <GradientContainer
        dark
        containerProps={{
          className: 'flex flex-col space-y-16 md:space-y-32'
        }}
      >
        <Heading
          as="span"
          outline
          className="text-3xl lg:text-4xl font-semibold"
        >
          Impressum
        </Heading>

        <div className="flex flex-col gap-y-4">
          <h2 className="font-semibold mt-4">Impressum / Pflichtangaben gem. § 5 Telemediengesetz (TMG)</h2>
          <p>Dr. med. Eva Maria Pless<br/>Schöne Aussicht 3<br/>36110 Schlitz<br/>E-Mail: info at dr-eva-maria-pless.de<br/>www.dr-eva-maria-pless.de</p>
          <h2 className="font-semibold mt-4">Gesetzliche Berufsbezeichung:</h2>
          <p>Ärztin, Approbation verliehen durch das Bundesland Nordrhein-Westfalen (28.05.1979), Fachärztin für Chirurgie (Bundesland Hessen, 05.03.1989), Teilgebietsbezeichnung Plastische Chirurgie (Bundesland Nordrhein-Westfalen, 16.12.1993),
            Fachärztin für Plastische und Ästhetische Chirurgie (Bundesland Hessen, 01.08.1997).</p>
          <h2 className="font-semibold mt-4">Zuständige Ärztekammer:</h2>
          <p>Landesärztekammer Hessen<br/><br/>Im Vogelsgesang 3<br/>60488 Frankfurt am Main<br/><br/>Postfach 90 06 69<br/>60446 Frankfurt am Main<br/><br/>Fon: 069 97672-0<br/>Fax: 069 97672-128<br/><br/>E-Mail: info at laekh.de<br/>www.laekh.de
          </p>
          <h2 className="font-semibold mt-4">Unterliegendes Berufsrecht:</h2>
          <p>Berufsordnung der Landesärztekammer Hessen abrufbar unter <a
            href="https://www.laekh.de/fileadmin/user_upload/Aerzte/Rund_ums_Recht/Rechtsquellen/Berufsordnung.pdf" target="_blank" className="underline text-blue-500 hover:text-blue-400">https://www.laekh.de/fileadmin/user_upload/Aerzte/Rund_ums_Recht/Rechtsquellen/Berufsordnung.pdf </a><br/>Heilberufe-Kammergesetz
            (abrufbar unter <br/><a href="https://www.laekh.de/fileadmin/user_upload/Aerzte/Rund_ums_Recht/Rechtsquellen/Heilberufsgesetz.pdf" target="_blank" className="underline text-blue-500 hover:text-blue-400">https://www.laekh.de/fileadmin/user_upload/Aerzte/Rund_ums_Recht/Rechtsquellen/Heilberufsgesetz.pdf</a></p>
          <h2 className="font-semibold mt-4">Externe Links (Verweise zu anderen Internetseiten):</h2>
          <p>Mit Urteil vom 12.05.1998 wurde durch das LG Hamburg entschieden, dass durch Setzen eines Links eine Verantwortlichkeit des Diensteanbieters auch für die Inhalte der Seiten entstehen kann, auf die verwiesen wird, es sei denn, dass sich
            der Diensteanbieter ausdrücklich vom Inhalt dieser Seiten distanziert.<br/>Für die Seiten dieses Internetauftritts gilt daher:</p> <p>Die Inhalte externer Links werden nicht geprüft. Sie unterliegen ausschließlich der Haftung der
          jeweiligen Anbieter. Die Herausgeberin dieser Seiten hat auf die Inhalte fremder Seiten keinen Einfluss und kann deshalb dafür auch keinerlei Gewähr übernehmen. Die Inhalte dieser Seiten werden insbesondere nicht Bestandteil der Seiten
          dieses Internetauftritts.</p> <p><b>Hinweis:<br/>Externe Links anderer Anbieter, mit denen auf diese Seiten verwiesen wird, unterliegen nicht der Kontrolle der Herausgeberin. Bitte bringen Sie solche Verweise daher nicht mit der Praxis Dr.
          Pless in Verbindung.</b></p>
          <h2 className="font-semibold mt-4">Schutzrechtsverletzungen:</h2>
          <p>Im Fall des Verdachtes einer Schutzrechtsverletzung durch diese Seiten wird darum gebeten, dies umgehend per eMail an info@dr-eva-maria-pless.de oder per Telefax an 0661/9014134 mitzuteilen, damit zügig Abhilfe geschaffen werden kann. Es
            wird garantiert, dass zu Recht beanstandete Passagen unverzüglich entfernt werden, ohne dass die Einschaltung eines Rechtsbeistandes erforderlich ist.<br/><br/>Es wird ausdrücklich darauf hingewiesen, dass die Einschaltung eines Anwaltes
            zur für die Diensteanbieterin kostenpflichtigen Abmahnung nicht deren wirklichem oder mutmaßlichem Willen entspricht. Die Anbieterin ist zur Vermeidung unnötiger Rechtsstreitigkeiten und Kosten bei vermuteter Schutzrechtsverletzung im
            Vorfeld zunächst durch den Beschwerdeführer persönlich zu kontaktieren.<br/><br/>Kostennoten anwaltlicher Abmahnungen ohne vorhergehende Kontaktaufnahme mit der Diensteanbieterin werden unter Hinweis auf die Schadensminderungspflicht des
            Beschwerdeführers als unbegründet zurückgewiesen.</p>
        </div>
      </GradientContainer>
    </Layout>
  )
}
